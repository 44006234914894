// utils
import { COUNTRY } from 'utils/country';

export const drugManufacturersString = (userLocaleCountryCode, user, minimunPurchase, drugManufacturer) => {
    const EX4856 = user?.EX4856;
    const drugManufacturersNames: any = EX4856
        ? drugManufacturer.filter((d: any) => minimunPurchase.includes(d.id))?.map((d) => d.name)
        : [];
    const EX4903: boolean = user?.EX4903;

    let drugManufacturersString: string = '';
    let connectorLabel: string = '';
    switch (userLocaleCountryCode) {
        case COUNTRY.BR:
            connectorLabel = ' e ';
            break;
        case COUNTRY.US:
            connectorLabel = ' and ';
            break;
        default:
            connectorLabel = ' y ';
            break;
    }
    if (drugManufacturersNames?.length > 1) {
        const lastWord = drugManufacturersNames?.pop(); // Remove and get the last word
        drugManufacturersString = drugManufacturersNames?.join(', ') + EX4903 ? connectorLabel : ' y ' + lastWord;
    } else {
        drugManufacturersString = drugManufacturersNames?.join(', '); // If there is only one word, do not add "and"
    }

    return drugManufacturersString;
};
