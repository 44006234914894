import { useTranslation } from 'react-i18next';
// configs
import { EX4135, EX4201 } from 'config/flags';
// context
import { useDragAndDrop } from 'context/dragAndDropContext';
import { usePointOfSale } from 'context';
// rsuite
import { Button, ButtonToolbar } from 'rsuite';
// utils
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useUser } from 'utils/hooks';
import { isZettiFormat } from 'utils/pointOfSale';
// style
import styles from './ModalDontHaveCredentials.module.css';

const ModalDontHaveCredentials = ({ dontHaveCredentialsModal, setDontHaveCredentialsModal, clientId, posId }) => {
    const { t } = useTranslation();
    const { userLocaleCountryCode, user } = useUser({});
    if (EX4201 || EX4135) useChangeLanguage(userLocaleCountryCode);
    const EX5943 = user?.EX5943; //epic rediseño home
    const { pointOfSale } = usePointOfSale();

    const { setVisibleDragDrop, handleDragLeave } = useDragAndDrop();
    const handlerRedirect = () => {
        setDontHaveCredentialsModal(false);
        window.location.href = `/${clientId}/settings/POS/${posId}/posDetails`;
    };

    const abortConnected = () => {
        setDontHaveCredentialsModal(false);
        if (EX5943) {
            if (isZettiFormat(pointOfSale)) {
                handleDragLeave();
            } else {
                setVisibleDragDrop(false);
            }
        } else {
            setVisibleDragDrop(false);
        }
    };

    return (
        <div
            className={styles['wrapper-card-container-fullblur']}
            style={{ display: dontHaveCredentialsModal ? 'flex' : 'none' }}
        >
            <div className={styles['container-card']}>
                <img src={'/assets/images/connect-credential.svg'} alt="IsologoHorizontal" className={styles['icon']} />
                <p className={styles['title']}>
                    {EX4135
                        ? t('efficientPurchasing.comparative.efficientPurchasing_comparativeTwentyNine')
                        : 'Todavía no podés iniciar una comparativa'}
                </p>
                <p className={styles['subtitle']}>
                    {!EX4201
                        ? 'Necesitas tener al menos dos droguerías activas para poder comenzar una compra.'
                        : t('modals.credentialModal_NeedTwoDrugstores')}
                </p>
                <ButtonToolbar>
                    <Button
                        className={`button__do-it-later-1 ${styles['close-button']}`}
                        onClick={() => abortConnected()}
                    >
                        Lo hago más tarde
                    </Button>
                    <Button
                        className={`button__connect-drugstore-2 ${styles['config-button']}`}
                        onClick={handlerRedirect}
                    >
                        {!EX4201 ? 'Conectar droguerías' : t('modals.credentialModal_ConnectDrugstores')}
                    </Button>
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default ModalDontHaveCredentials;
